import dayjs from 'dayjs'
import { Duration, TimeRangeTimeLike } from '../gen/service/common'
import pluralize from 'pluralize'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(duration)
dayjs.extend(LocalizedFormat)
dayjs.extend(timezone)
export type TimeUnit = 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years'

const TimeUnitShortNames = {
  s: 'seconds',
  m: 'minutes',
  h: 'hours',
  d: 'days',
  w: 'weeks',
  M: 'months',
  y: 'years'
}

function timeUnit2String(u: TimeUnit) {
  if (u == 'months') {
    return 'M'
  }
  return u[0]
}

export const FULL_DATE_TIME_PATTERN = 'MMM D, YYYY HH:mm:ss'

export interface RelativeTime {
  value: number
  unit?: TimeUnit
  sign: 0 | 1 | -1
  align?: TimeUnit
}

export const now: RelativeTime = { value: 0, sign: 0 }
export type DateTimeValue = dayjs.Dayjs | RelativeTime

export const ago = (value: number, unit: TimeUnit): RelativeTime => {
  return {
    value,
    unit,
    sign: -1
  }
}

export const previous = (value: number, unit: TimeUnit): RelativeTime => {
  return {
    value,
    unit,
    sign: -1,
    align: unit
  }
}

export const fromNow = (value: number, unit: TimeUnit): RelativeTime => {
  return {
    value,
    unit,
    sign: 1
  }
}

export const isNow = (value: DateTimeValue): boolean => {
  return isRelativeTime(value) && (value as RelativeTime).value == 0 && (value as RelativeTime).align == null
}

export const isRelativeTime = (value: DateTimeValue): boolean => {
  return !dayjs.isDayjs(value)
}

export function fromTimeLike(tl?: TimeRangeTimeLike): DateTimeValue | undefined {
  if (!tl) {
    return undefined
  }
  if (tl.relativeTime) {
    const value = tl.relativeTime.value || 0
    return {
      value: Math.abs(value),
      unit: tl.relativeTime.unit as TimeUnit,
      sign: value < 0 ? -1 : 1
    }
  }
  return dayjs.unix(parseInt(tl.absoluteTime || ''))
}

export function toTimeLike(t?: DateTimeValue): TimeRangeTimeLike {
  if (dayjs.isDayjs(t)) {
    return {
      absoluteTime: `${t.unix()}`
    }
  }
  t = t as RelativeTime
  return {
    relativeTime: {
      unit: t.unit,
      value: t.sign * t.value,
      align: t.align
    }
  }
}

export function toDayjs(t: DateTimeValue, asStart = true): dayjs.Dayjs {
  if (dayjs.isDayjs(t)) {
    return t
  }
  t = t as RelativeTime
  const ret = dayjs().add(t.sign * t.value, t.unit || 'second')
  if (t.align) {
    if (asStart) {
      return ret.startOf(t.align)
    } else {
      return ret.endOf(t.align)
    }
  }
  return ret
}

export function fromNumber(n: number): DateTimeValue {
  return dayjs(new Date(n))
}

export function parseRelativeTime(s?: string): DateTimeValue | null {
  if (!s) {
    return null
  } else {
    const regex = /^(now)?(([-+])(\d+)([Mhwdmy]))?(\/([Mwdy]))?$/
    const m = s.match(regex)
    if (m) {
      const [, , , sign, value, units, , align] = m
      return {
        value: value ? parseInt(value) : 0,
        unit: units ? TimeUnitShortNames[units] : undefined,
        sign: sign === '-' ? -1 : 1,
        align: align ? TimeUnitShortNames[align] : undefined
      }
    }
    return null
  }
}

export function parseDateTime(s?: string): DateTimeValue | null {
  if (!s) {
    return null
  } else {
    const t = parseRelativeTime(s)
    if (t) {
      return t
    } else {
      const number = Number(s)
      if (isNaN(number)) {
        // try parse ISO date
        const d = dayjs(s)
        return d.isValid() ? d : null
      } else if (number < 10000000000) {
        // unix timestamp
        const d = dayjs.unix(number)
        return d.isValid() ? d : null
      } else {
        // date in milliseconds
        const d = dayjs(new Date(number))
        return d.isValid() ? d : null
      }
    }
  }
}

// default time range is 30 days
export const DEFAULT_FROM = ago(30, 'days')
export const DEFAULT_TO = now
export const DEFAULT_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone

export function dateTimeToString(d: DateTimeValue | undefined | null): string {
  if (dayjs.isDayjs(d)) {
    return d.unix() + ''
  } else if (d == null) {
    return ''
  } else {
    const t = d as RelativeTime
    return relativeTimeToString(t)
  }
}

export const relativeTimeToString = (t?: RelativeTime) => {
  if (!t) {
    return ''
  }
  let str = 'now'
  if (t.sign !== 0 && t.value !== 0 && t.unit) {
    str += `${t.sign * t.value}${timeUnit2String(t.unit)}`
  }
  if (t.align) {
    return str + '/' + timeUnit2String(t.align)
  }
  return str
}

export function isBefore(a: DateTimeValue | null | undefined, b: DateTimeValue | null | undefined): boolean {
  if (a == null || b == null) {
    return false
  }
  return toDayjs(a).isBefore(toDayjs(b, false))
}

export function timeRangeToDisplayString(from: DateTimeValue, to: DateTimeValue): string {
  let result: string
  if (isNow(to)) {
    if (isRelativeTime(from)) {
      const t = from as RelativeTime
      result = `Past ${pluralize(t.unit, t.value, true)}`
    } else {
      result = `Since ${timeToDisplayString(from)}`
    }
  } else {
    result = `${timeToDisplayString(from)} - ${timeToDisplayString(to)}`
  }
  return result
}

export function timeToDisplayString(t: DateTimeValue): string {
  if (isRelativeTime(t)) {
    t = t as RelativeTime
    if (t.sign === 0 || t.value === 0) {
      return 'now'
    }
    if (t.sign < 0) {
      return `${pluralize(t.unit, t.value, true)} ago`
    } else {
      return `${pluralize(t.unit, t.value, true)} after`
    }
  } else {
    return toDayjs(t).format('LLL')
  }
}

export function durationToSeconds(d?: Duration) {
  if (!d) {
    return 0
  }
  return dayjs.duration(d.value || 0, d.unit as dayjs.UnitTypeShort).asSeconds()
}

export function durationToString(d: Duration) {
  return `${d.value}${d.unit}`
}

export function durationToLongString(d: Duration) {
  const unit = TimeUnitShortNames[d.unit!]
  return pluralize(unit, d.value)
}

export function durationToShort(d: Duration) {
  let short = ''
  if (d.unit == 'month') {
    short = 'M'
  } else if (d.unit) {
    short = d.unit[0]
  }

  return `${d.value}${short}`
}

export function displayDate(d?: Date | string): string {
  if (typeof d == 'string') {
    return dayjs(parseInt(d)).format('LLL')
  } else {
    return dayjs(d).format('LLL')
  }
}

export function pickRangeByTimeRange(
  currentTime: DateTimeValue,
  startTime?: DateTimeValue,
  endTime?: DateTimeValue
): {
  startTime: DateTimeValue
  endTime: DateTimeValue
} {
  const start = toDayjs(startTime || currentTime)
  const end = toDayjs(endTime || currentTime)
  const current = toDayjs(currentTime)

  const offset = end.diff(start, 'minute')

  if (offset >= 60 * 24 * 365) {
    // 1year: 1week
    return {
      startTime: current,
      endTime: current.add(1, 'week')
    }
  } else if (offset >= 60 * 24 * 30) {
    // 1month: 1day
    return {
      startTime: current,
      endTime: current.add(1, 'day')
    }
  } else if (offset >= 60 * 24 * 7) {
    // 1week: 12hour
    return {
      startTime: current,
      endTime: current.add(12, 'hour')
    }
  } else if (offset >= 60 * 24 * 2) {
    // 2day: 2hour
    return {
      startTime: current,
      endTime: current.add(2, 'hour')
    }
  } else if (offset >= 60 * 24) {
    // 1day: 1hour
    return {
      startTime: current,
      endTime: current.add(1, 'hour')
    }
  } else if (offset >= 60 * 4) {
    // 4hour: 5min
    return {
      startTime: current,
      endTime: current.add(5, 'minute')
    }
  } else if (offset >= 60) {
    // 1hour: 1min
    return {
      startTime: current,
      endTime: current.add(1, 'minute')
    }
  } else if (offset >= 30) {
    // 30min: 30s
    return {
      startTime: current,
      endTime: current.add(30, 'second')
    }
  }

  return {
    startTime: current,
    endTime: current.add(5, 'second')
  }
}

export function pickRangeByInterval(
  currentTime: DateTimeValue,
  interval: Duration
): {
  startTime: DateTimeValue
  endTime: DateTimeValue
} {
  const start = toDayjs(currentTime)
  const end = start.add(durationToSeconds(interval), 'second')

  return {
    startTime: start,
    endTime: end
  }
}

export function computeDuration(startTime: DateTimeValue, endTime: DateTimeValue) {
  return dayjs.duration(toDayjs(endTime).diff(toDayjs(startTime)))
}

export function parseDuration(s: string): Duration {
  const m = s.match(/(\d+)([a-z]+)/)
  if (m) {
    const [, value, unit] = m
    return {
      value: parseInt(value),
      unit: TimeUnitShortNames[unit]
    }
  } else {
    return {
      value: 0,
      unit: 'second'
    }
  }
}

export function alignTime(time: DateTimeValue, step: Duration, tz?: string, align: 'start' | 'end' = 'start') {
  try {
    const d = toDayjs(time)
    const seconds = durationToSeconds(step)
    const tzOffset = (tz ? d.tz(tz).utcOffset() : 0) * 60
    const offset = (d.unix() + tzOffset) % seconds
    if (offset === 0) {
      return time
    }

    return align == 'start' ? d.subtract(offset, 'second') : d.add(seconds - offset, 'second')
  } catch (e) {
    // dayjs.tz may throw exception some unknown reason
    console.error(e)
    return time
  }
}

export function timeBefore(time: DateTimeValue, duration: Duration, asStart: boolean = true): DateTimeValue {
  if (isRelativeTime(time)) {
    const rt = time as RelativeTime
    if (rt.unit == null || time == now) {
      return { sign: -1, unit: TimeUnitShortNames[duration.unit!], value: duration.value! }
    }
    if (rt.align) {
      const t = toDayjs(rt, asStart)
      return t.subtract(duration.value!, TimeUnitShortNames[duration.unit!])
    }

    const t = toDayjs(time)
    const nt = t.subtract(duration.value!, TimeUnitShortNames[duration.unit!])
    const unit = 'days'
    const value = nt.diff(dayjs(), unit)
    return { sign: value < 0 ? -1 : 1, unit, value: Math.abs(value) }
  } else {
    const t = toDayjs(time)
    return t.subtract(duration.value!, TimeUnitShortNames[duration.unit!])
  }
}

const tzOffsetCache = new Map<string, number>()
function cachedTzOffset(tz: string, origin: dayjs.Dayjs) {
  const key = tz + origin.format('YYYY-MM-DD')
  if (tzOffsetCache.has(key)) {
    return tzOffsetCache.get(key)!
  }
  const tzDate = origin.tz(tz, true)
  const diff = origin.diff(tzDate, 'minute')
  tzOffsetCache.set(key, diff)
  return diff
}

export function shiftTimezone(time: Date | dayjs.Dayjs, tz?: string) {
  const origin = dayjs.isDayjs(time) ? time : dayjs(time)
  const diff = cachedTzOffset(tz || DEFAULT_TZ, origin)
  return origin.add(diff, 'minute').toDate()
}
