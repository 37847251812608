'client only'

import { MultiGetTagByAddressRequest, TagService, GetTagByAddressResponse } from 'gen/service/tag/protos/tag.pb'
import * as localForage from 'localforage'
import { useAtomValue } from 'jotai'
import { tagCache } from 'lib/debug/atoms'
import { useApiWithoutToken } from './use-api'

const store = localForage.createInstance({
  name: 'addressTags'
})

export const chainIdToNumber = (chainId?: string) => {
  if (!chainId) return undefined
  if (chainId.startsWith('0x')) {
    return parseInt(chainId, 16)
  }
  return parseInt(chainId)
}

const getCacheKey = (address?: string, chainId?: string) => {
  return `${address?.toLowerCase()}-${chainId}`
}

export const useAddressTag = (address?: string, _chainId?: string) => {
  const addressMap = useAtomValue(tagCache)
  const lowerAddress = address?.toLowerCase()
  return {
    data: lowerAddress ? addressMap.get(lowerAddress) : undefined
  }
}

export const getTagsByAddressesFromCache = async (addresses: string[], _chainId?: string) => {
  const chainId = _chainId?.startsWith('sui_') ? _chainId : chainIdToNumber(_chainId)
  let req: MultiGetTagByAddressRequest | undefined
  if (addresses?.length && chainId !== undefined) {
    req = {
      requests: addresses.map((addr) => ({ address: addr, chainId: chainId.toString() }))
    }
  }
  const nameMap = new Map<string, GetTagByAddressResponse>()
  if (req) {
    try {
      // try cache first
      const promiseList = req.requests?.map((r) => {
        return store.getItem<GetTagByAddressResponse>(getCacheKey(r.address, r.chainId)).then((cachedRes) => {
          if (cachedRes && r.address) {
            nameMap.set(r.address, cachedRes)
          }
        })
      })
      await Promise.all(promiseList || [])
    } catch {
      console.error('Failed to get tags by addresses from local cache')
    }
  }
  return nameMap
}

export const getTagsByAddresses = async (addresses: string[], _chainId?: string) => {
  const chainId = _chainId?.startsWith('sui_') ? _chainId : chainIdToNumber(_chainId)
  let req: MultiGetTagByAddressRequest | undefined
  if (addresses?.length && chainId !== undefined) {
    req = {
      requests: addresses.map((addr) => ({ address: addr, chainId: chainId.toString() }))
    }
  }
  const nameMap = new Map<string, GetTagByAddressResponse>()
  if (req) {
    try {
      const res = await TagService.MultiGetTagByAddress(req)
      res.responses?.forEach((r) => {
        if (r.primaryName || r.token) {
          store.setItem(getCacheKey(r.address, chainId?.toString()), r)
        }
        if (r.address) {
          nameMap.set(r.address, r)
        }
      })
    } catch {
      console.error('Failed to get tags by addresses')
    }
    return nameMap
  }
  return nameMap
}

async function getTagByAddress(req, ...params) {
  if (req.address && req.chainId) {
    const cacheKey = getCacheKey(req.address, req.chainId)
    const cached = await store.getItem<GetTagByAddressResponse>(cacheKey)
    if (cached) {
      return cached
    }
  }

  return TagService.GetTagByAddress(req, ...params).then((res) => {
    if (res.primaryName || res.token) {
      store.setItem(getCacheKey(res.address, req.chainId), res)
    }
    return res
  })
}

export const useAddressTagFromServer = (address?: string, _chainId?: string) => {
  const chainId = _chainId?.startsWith('sui_') ? _chainId : chainIdToNumber(_chainId)
  const lowerAddress = address?.toLowerCase()
  const ret = useApiWithoutToken(
    getTagByAddress,
    lowerAddress && chainId ? { address: lowerAddress, chainId } : undefined,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false
    }
  )

  return ret
}
