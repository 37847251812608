import { getChainName } from '@sentio/chain'

// @deprecate migrate to @sentio/chain get chainTYpe
export function getChainType(chainId?: string | number): string {
  const id = String(chainId).toLowerCase()
  if (id.startsWith('sol')) {
    return 'solana'
  }
  if (id.startsWith('sui')) {
    return 'sui'
  }
  if (id.startsWith('apt')) {
    return 'aptos'
  }
  return 'EVM'
}

export function chainInitialLabel(chainType: string) {
  switch (chainType) {
    case 'aptos':
      return 'Initial start version'
    case 'sui':
      return 'Initial start checkpoint'
    default:
      return 'Initial start block number'
  }
}

export function chainBlockLabel(chainType: string) {
  switch (chainType) {
    case 'aptos':
      return 'Version'
    case 'sui':
      return 'Checkpoint'
    default:
      return 'Block number'
  }
}
