/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../fetch.pb"

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (
    keyof T extends infer K ?
      (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K>
        : never)
    : never);
export type GetTagByAddressRequest = {
  chainId?: string
  address?: string
}

export type GetTagByAddressResponse = {
  address?: string
  primaryName?: string
  token?: TokenTag
  names?: NameTag[]
}

export type MultiGetTagByAddressRequest = {
  requests?: GetTagByAddressRequest[]
}

export type MultiGetTagByAddressResponse = {
  responses?: GetTagByAddressResponse[]
}

export type NameTag = {
  label?: string
  dataSource?: string
  updatedAt?: string
  expiresAt?: string
}


type BaseTokenTag = {
}

export type TokenTag = BaseTokenTag
  & OneOf<{ erc20: ERC20Token; erc721: ERC721Token; suiCoin: SuiCoin }>

export type ERC20Token = {
  contractAddress?: string
  name?: string
  symbol?: string
  decimals?: number
  logo?: string
  website?: string
}

export type ERC721Token = {
  contractAddress?: string
  name?: string
  symbol?: string
  logo?: string
  website?: string
}

export type SuiCoin = {
  coinType?: string
  objectId?: string
  symbol?: string
  decimals?: number
  logo?: string
  bridge?: boolean
  verified?: boolean
}

export class TagService {
  static GetTagByAddress(req: GetTagByAddressRequest, initReq?: fm.InitReq): Promise<GetTagByAddressResponse> {
    return fm.fetchReq<GetTagByAddressRequest, GetTagByAddressResponse>(`/api/v1/tag/${req["address"]}/${req["chainId"]}?${fm.renderURLSearchParams(req, ["address", "chainId"])}`, {...initReq, method: "GET"})
  }
  static MultiGetTagByAddress(req: MultiGetTagByAddressRequest, initReq?: fm.InitReq): Promise<MultiGetTagByAddressResponse> {
    return fm.fetchReq<MultiGetTagByAddressRequest, MultiGetTagByAddressResponse>(`/api/v1/tag/multi`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}