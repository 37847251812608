import nRouter, { NextRouter, useRouter } from 'next/router'
import { useCallback, useEffect, useState, useMemo, useRef, Dispatch } from 'react'
import { isEqual } from 'lodash'
import { SetStateAction } from 'jotai'

export function useUrlQuery(field: string, initialValue?: string): [string | undefined, (value?: string) => void] {
  const router = useRouter()
  const [state, setState] = useState(initialValue)
  useEffect(() => {
    const value = (router.query[field] as string) || initialValue
    setState((preState) => (preState !== value ? value : preState))
  }, [router.query, field, initialValue])

  const routerRef = useRef<NextRouter | null>()
  useEffect(() => {
    routerRef.current = router
  }, [router])

  const setQueryParam = useCallback(
    (value?: string) => {
      const router = routerRef.current
      if (!router) {
        return
      }
      setState(value)
      const options = {
        pathname: router.pathname,
        query: {
          ...router.query
        },
        hash: router.asPath.split('#')[1]
      }
      if (value == null) {
        delete options.query[field]
      } else {
        options.query[field] = value
      }
      router.push(options, undefined, {
        shallow: true
      })
    },
    [field]
  )

  return [state, setQueryParam]
}

const DATETIME_KEYS = ['from', 'to']

export function useUrlQueries<T extends { [key: string]: any }>(
  prefix: string,
  initialState: T,
  keepInitialValue?: boolean
): [T, (values: T) => void] {
  const router = useRouter()
  const [state, setState] = useState<T>(initialState)
  const prefixRef = useRef(prefix)

  useEffect(() => {
    const newValues = Object.keys(router.query).reduce((acc, queryKey) => {
      let key: string
      if (prefix && queryKey.startsWith(prefix)) {
        key = queryKey.slice(prefix.length)
      } else if (queryKey in initialState) {
        key = queryKey
      } else {
        // not our value, ignore
        return acc
      }

      const value = router.query[queryKey]
      if (DATETIME_KEYS.includes(queryKey)) {
        if (typeof value === 'string') {
          acc[key] = value.replaceAll('"', '')
        }
        return acc
      }

      switch (value) {
        case 'null':
          acc[key] = null
          break
        case '':
          acc[key] = value
          break
        default:
          try {
            const v = JSON.parse(value as string)
            acc[key] = v == null ? initialState[key] : v
          } catch (e) {
            acc[key] = initialState[key]
          }
      }

      return acc
    }, {})
    setState((preState) => {
      if (keepInitialValue && isEqual(preState, initialState) && Object.keys(newValues).length === 0) {
        return preState
      }
      if (!isEqual(preState, newValues)) {
        return newValues as T
      }
      return preState
    })
  }, [router.query, initialState, prefix, keepInitialValue])

  useEffect(() => {
    prefixRef.current = prefix
  }, [prefix])

  const setQueryParams: Dispatch<SetStateAction<T>> = useCallback((values) => {
    setState((state) => {
      const newState = typeof values === 'function' ? (values as any)(state) : values
      setTimeout(() => {
        const prefix = prefixRef.current || ''
        const router = nRouter
        const queryValues = Object.keys(newState).reduce((acc, key) => {
          acc[`${prefix}${key}`] = JSON.stringify(newState[key])
          return acc
        }, {})
        for (const key in router.query) {
          const isOurKey = (prefix && key.startsWith(prefix)) || key in initialState
          if (!isOurKey) {
            // copy over any other query params
            queryValues[key] = router.query[key]
          }
        }
        const hash = router.asPath.split('#')[1]
        router.push(
          {
            pathname: router.pathname,
            query: queryValues,
            hash
          },
          undefined,
          {
            shallow: true
          }
        )
      }, 0)
      return newState
    })
  }, [])

  return [state, setQueryParams]
}
