import React from 'react'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import classNames from 'lib/classnames'
import { PolygonChainIcon, LineaIcon } from 'components/chains/ChainIcons'
import { SvgFolderContext } from 'lib/extension/context'
import { useContext } from 'react'

const scanIconMap = {
  polygonScan: {
    icon: PolygonChainIcon,
    urlRegex: /^https:\/\/polygonscan\.com/
  },
  lineaScan: {
    icon: LineaIcon,
    urlRegex: /^https:\/\/lineascan\.build/
  },
  etherScan: {
    icon: (props) => {
      const folderPath = useContext(SvgFolderContext)
      return <img src={`${folderPath}/etherscan-logo-circle.svg`} alt="Etherscan Logo" {...props} />
    },
    urlRegex: /^https:\/\/[\w-]*\.?etherscan\.io/
  },
  bscScan: {
    icon: (props) => {
      const folderPath = useContext(SvgFolderContext)
      return <img src={`${folderPath}/bscscan-logo-circle.svg`} alt="Etherscan Logo" {...props} />
    },
    urlRegex: /^https:\/\/bscscan\.com/
  },
  blockscout: {
    icon: (props) => {
      const folderPath = useContext(SvgFolderContext)
      return <img src={`${folderPath}/blockscout-logo.png`} alt="Blockscout Logo" {...props} />
    },
    urlRegex: /^https:\/\/[\w-]+\.?blockscout\.com/
  }
}

export const ExternalLogo = ({ link, className }: { link?: string; className?: string }) => {
  if (!link) {
    return <span className={className} />
  }

  const scanIcon = Object.values(scanIconMap).find(({ urlRegex }) => urlRegex.test(link))
  if (scanIcon) {
    return React.createElement(scanIcon.icon, { className: classNames(className, 'rounded-full hover:ring-2') })
  }
  return <ArrowTopRightOnSquareIcon className={className} />
}
