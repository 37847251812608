import linkifyUrls from 'linkify-urls'
import * as DOMPurify from 'dompurify'
import { memo } from 'react'
import { isString, isUndefined, isNull } from 'lodash'

if (DOMPurify?.addHook) {
  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank')
    }
    // set non-HTML/MathML links to xlink:show=new
    if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
      node.setAttribute('xlink:show', 'new')
    }
  })
}

export const LinkifyText = memo(function LinkifyText({ text, className }: { text: any; className?: string }) {
  if (isUndefined(text) || isNull(text)) {
    return null
  }
  if (!isString(text)) {
    if (text.toString) {
      return <span className={className}>{text.toString()}</span>
    }
    return null
  }
  const linkStr = linkifyUrls(text, {
    attributes: {
      class: 'text-primary hover:underline',
      target: '_blank',
      rel: 'noopener noreferrer'
    }
  })
  return <span className={className} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(linkStr) }} />
})
