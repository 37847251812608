import classNames from 'lib/classnames'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon as SelectorIcon, XMarkIcon as XIcon } from '@heroicons/react/20/solid'
import { useMemo, useRef, useState } from 'react'
interface Props {
  options: string[]
  value?: string
  onChange: (value?: string) => void
  className?: string
  inputClassName?: string
  maxOptions?: number
  placeholder?: string | JSX.Element
  error?: string
  name?: string
  displayFn?: (option: string, active?: boolean) => React.ReactNode
  clearAfterSelect?: boolean // clear input after select, make it easier to select multiple options
}

export function ComboInput({
  options,
  value,
  onChange,
  className,
  inputClassName,
  maxOptions,
  error,
  placeholder,
  name,
  displayFn,
  clearAfterSelect
}: Props) {
  const [input, setInput] = useState('')

  const filteredOptions = useMemo(() => {
    const filtered = input ? options.filter((o) => String(o).toLowerCase().includes(input?.toLowerCase())) : options
    return maxOptions || 0 > 0 ? filtered.slice(0, maxOptions) : filtered
  }, [options, input, maxOptions])

  function clearSelection() {
    setInput('')
    onChange(undefined)
  }

  function onSelectChange(value) {
    onChange(value)
    if (clearAfterSelect) {
      setInput('')
    } else {
      setInput(value)
    }
  }

  return (
    <Combobox
      nullable
      as="div"
      className={classNames(className, 'flex h-full  flex-grow')}
      value={value}
      onChange={onSelectChange}
    >
      <div
        className={classNames(
          inputClassName,
          'relative inline-flex w-full flex-grow items-center border hover:shadow',
          error
            ? 'border-red-500 focus:border-red-500  focus:ring-red-500'
            : 'focus:border-primary-500 focus:ring-primary-500 border-gray-300 '
        )}
      >
        <Combobox.Input
          as="div"
          tabIndex={0}
          className={classNames(
            'text-ilabel w-[calc(100%-6rem)] flex-grow rounded border-0 py-1 pr-0 ring-0 focus:outline-none focus:ring-0'
          )}
          onChange={() => {
            /**/
          }}
          aria-label={name}
        >
          <Combobox.Button
            className={classNames(
              value ? 'text-text-foreground' : 'text-gray-400',
              'left-0 w-full cursor-pointer truncate'
            )}
            as="div"
          >
            {value || placeholder}
          </Combobox.Button>
        </Combobox.Input>
        <div
          role={'button'}
          aria-label={'clear'}
          className={classNames('inset-y-0 flex cursor-pointer items-center p-1', value ? '' : 'hidden')}
          onClick={() => clearSelection()}
        >
          <XIcon
            className="hover:bg-primary-400 h-4 w-4 rounded-lg text-gray-400 hover:text-white"
            aria-hidden="true"
          />
        </div>
        <Combobox.Button
          className="inset-y-0 mx-2 flex items-center rounded-r-md focus:outline-none"
          aria-label="select"
        >
          <SelectorIcon className="text-gray h-4 w-4" aria-hidden="true" />
        </Combobox.Button>

        <Combobox.Options
          as="div"
          className="ring-primary-500 focus:ring-primary-500 divide-primary-500 dark:bg-sentio-gray-100 absolute top-0 z-10 min-w-full gap-0
                    divide-y  rounded-md bg-white py-1 text-sm shadow-lg ring-1"
          unmount={false}
        >
          <div>
            <Combobox.Input
              type="text"
              className={classNames('text-ilabel w-full border-0 px-2 py-0 ring-0 focus:outline-none focus:ring-0')}
              value={input}
              tabIndex={0}
              name={name}
              onChange={(event) => setInput(event.target.value)}
              autoComplete="off"
            />
          </div>
          <ul className="max-h-60 overflow-auto pt-1">
            {filteredOptions.map((m, idx) => (
              <Combobox.Option
                key={idx}
                value={m}
                className={({ active }) =>
                  classNames(
                    'text-ilabel relative flex cursor-default select-none py-2 pl-3 pr-1',
                    active ? 'bg-primary-600 text-white' : 'text-text-foreground'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block flex-1 truncate', selected && 'font-semibold')} title={m}>
                      {displayFn ? displayFn(m, active) : m}
                    </span>

                    {selected && (
                      <span
                        className={classNames('flex items-center pr-4', active ? 'text-white' : 'text-primary-600')}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
            {filteredOptions.length === 0 && (
              <li className="w-40 select-none px-3 py-2 text-gray-400">No matching results.</li>
            )}
          </ul>
        </Combobox.Options>
      </div>
    </Combobox>
  )
}
