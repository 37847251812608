import { useProject } from './use-project'
import { Permission } from 'gen/service/common'
import useApi from './use-api'
import { GetProcessorStatusResponseProcessorEx, ProcessorService, ProcessorVersionState } from 'gen/service/processor'
import { useCallback, useMemo } from 'react'
import { useUrlQuery } from './use-url-query'
import { ChainStateText, getChainProgress } from '../../components/processor/ProcessorChains'

export function useProjectVersions(projectId?: string) {
  const { project } = useProject([Permission.READ])
  const req = projectId ? { projectId } : project?.multiVersion ? { projectId: project.id } : null
  const { data, error, loading } = useApi(ProcessorService.GetProjectVersions, req)

  const versions = useMemo(() => data?.versions || [], [data])
  const [activeVersion, pendingVersion] = useMemo(() => {
    const active = versions.find(
      (v) => v.state == ProcessorVersionState.ACTIVE || v.state == ProcessorVersionState.UNKNOWN || v.state == undefined
    )
    const pending = versions.find((v) => v.state == ProcessorVersionState.PENDING)
    return [active, pending]
  }, [versions])

  const [version, setVersion] = useUrlQuery('version')

  const setCurrentVersion = useCallback(
    (version: number) => {
      if (version == 0 || version == activeVersion?.version) {
        setVersion(undefined)
      } else {
        setVersion(version.toString())
      }
    },
    [setVersion]
  )

  const currentVersion = useMemo(() => {
    if (version != null) {
      return parseInt(version)
    }
    if (activeVersion) {
      return activeVersion.version
    }
    if (pendingVersion) {
      return pendingVersion.version
    }
    return undefined
  }, [activeVersion, pendingVersion, version])

  const currentProcessor = useMemo(() => {
    return versions?.find((p) => p.version == currentVersion)?.processorId
  }, [currentVersion, versions])

  return {
    project,
    enabled: project?.multiVersion,
    versions,
    error,
    loading,
    currentVersion,
    activeVersion,
    pendingVersion,
    currentProcessor,
    setCurrentVersion
  }
}

export function userVersionStatus(processor?: GetProcessorStatusResponseProcessorEx) {
  let progress = 100
  let status: 'ok' | 'error' | 'backfill' | 'unknown' = 'ok'
  let errorText = ''
  if (!processor?.states?.length && !['PENDING', 'ACTIVE'].includes(processor?.versionState as string)) {
    errorText = 'No chains are configured for this processor'
    status = 'unknown'
  }
  for (const state of processor?.states || []) {
    if (state.status?.state !== 'PROCESSING_LATEST') {
      errorText =
        `Chain ${state.chainId} is in ${ChainStateText[state.status?.state ?? '']} state\n` +
        `Chart data may be outdated or incomplete.`
      progress = Math.min(getChainProgress(state) * 100, progress)
      status = 'backfill'
    }
  }
  if (processor?.processorStatus?.state === 'ERROR') {
    status = 'error'
    errorText = `Processor is in error state. Chart\n data may be outdated or incomplete.`
  }

  return {
    status,
    progress,
    errorText
  }
}
