import { atom } from 'jotai'
import { atomWithReducer } from 'jotai/utils'
import { WatchedVariable, Source } from './types'
import { KeyedMutator } from 'swr'
import { GetTagByAddressResponse } from 'gen/service/tag/protos/tag.pb'
import isEqual from 'lodash/isEqual'
import sortedUniq from 'lodash/sortedUniq'
import sortBy from 'lodash/sortBy'
import { SourceStore } from 'components/editor/SourceStore'

// call trace expand key
export const currentKey = atom<string>('0')

export const instructionIndex = atom<number>(0)
export const hoverLine = atom<number>(0)
export const transactionHash = atom<string | null>(null)
export const searchContractAddress = atom<string | null>(null)
export const searchFuncSig = atom<string | null>(null)
export const showTxnSlideover = atom<boolean>(false)
export const highlightCallTrace = atom<string | undefined>(undefined)

/**
 * debugger atoms
 */
type WatchAction = {
  type: 'add' | 'remove' | 'update' | 'clear'
  value: WatchedVariable
}

export const debuggerWatchList = atomWithReducer<WatchedVariable[], WatchAction>([], (prev, action) => {
  switch (action?.type) {
    case 'add':
      return [...prev, action.value]
    case 'remove':
      return prev.filter((item) => item.id !== action.value.id)
    case 'update':
      return prev.map((item) => (item.id === action.value.id ? action.value : item))
    case 'clear':
      return []
    default:
      return prev
  }
})

export const txnModel = atom<{
  getModelAsync?: (
    source: Source,
    saveReject?: (rejectFn: (reason: string) => void) => void
  ) => Promise<globalThis.monaco.editor.ITextModel>
  error?: string
  loading?: boolean
  store?: SourceStore
}>({
  loading: true
})

export const txnCallTraces = atom<{
  data?: any
  error?: any
  loading?: boolean
  mutate?: KeyedMutator<any>
}>({
  loading: true
})

export const txnSentioCallTraces = atom<{
  data?: any
  error?: any
  loading?: boolean
  mutate?: KeyedMutator<any>
}>({
  loading: true
})

export const getCacheKey = (address?: string, chainId?: string) => {
  return `${address?.toLowerCase()}-${chainId}`
}
export const tagAddressList = atom<string[]>([])
export const tagAddressSetter = atom(null, (get, set, _passedList: string[] | ((v: string[]) => string[])) => {
  const preList = get(tagAddressList)
  let isDiff = false
  const passedList = typeof _passedList === 'function' ? _passedList(preList) : _passedList
  passedList.forEach((v, k) => {
    if (!preList.includes(v)) {
      isDiff = true
    }
  })
  if (isDiff) {
    const newList = sortedUniq(sortBy([...preList, ...passedList]))
    set(tagAddressList, newList)
  }
})
export const tagCache = atom<Map<string, GetTagByAddressResponse>>(new Map())
export const tagCacheSetter = atom(null, (get, set, passedMap: Map<string, GetTagByAddressResponse>) => {
  const preMap = get(tagCache)
  let isDiff = false
  passedMap.forEach((v, k) => {
    if (!isEqual(preMap[k], v)) {
      isDiff = true
    }
  })
  if (isDiff) {
    const newMap = new Map([...preMap, ...passedMap])
    set(tagCache, newMap)
  }
})
export const tagCacheClear = atom(null, (get, set) => {
  set(tagCache, new Map())
})
