import React, { useMemo } from 'react'
import classNames from 'lib/classnames'
import get from 'lodash/get'

export interface ColumnProps<T> {
  title: React.ReactNode
  itemKey: string
  titleSrOnly?: boolean
  width?: number | string
  itemClassName?: string
  renderItem?: (v: any, col: T) => React.ReactNode
}

interface Props<T> {
  columns: ColumnProps<T>[]
  data?: T[]
  noDataElement?: React.ReactNode
  rowKey?: string
  onRowClick?: (row: T) => void
  borderded?: boolean
  theadClass?: string
  tbodyClass?: string
}

const BaseTable = function Table<T>({
  columns,
  data = [],
  noDataElement,
  rowKey,
  onRowClick,
  borderded,
  theadClass,
  tbodyClass
}: Props<T>) {
  const trClassName = useMemo(() => {
    return classNames('hover:bg-primary-50 dark:hover:bg-sentio-gray-300 group', onRowClick ? 'cursor-pointer' : '')
  }, [onRowClick])
  const tdClassName = useMemo(() => 'whitespace-nowrap py-2 px-2 text-icontent font-medium text-text-foreground', [])

  return (
    <table className={classNames('min-w-full divide-y ', borderded ? 'border-border-color border' : '')}>
      <colgroup>
        {columns.map((column, index) => (
          <col span={1} style={{ width: column.width ?? 'auto' }} key={index}></col>
        ))}
      </colgroup>
      <thead className={theadClass} style={{ borderTop: '0px' }}>
        <tr>
          {columns.map((column, index) => (
            <th
              key={index}
              scope="col"
              className="text-ilabel text-text-foreground whitespace-nowrap px-2 py-2 text-left font-semibold first:rounded-l-md last:rounded-r-md"
            >
              {column.titleSrOnly ? <span className="sr-only">{column.title}</span> : column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={classNames('dark:bg-sentio-gray-100  divide-y bg-white', tbodyClass)}>
        {data.length > 0 ? (
          <>
            {data.map((item, index) => {
              return (
                <tr
                  key={rowKey ? item[rowKey] : item['id'] || index}
                  className={trClassName}
                  onClick={() => {
                    onRowClick && onRowClick(item)
                  }}
                >
                  {columns.map((column) => {
                    const { itemKey, renderItem, itemClassName = '' } = column
                    const colValue = get(item, itemKey)
                    return (
                      <td key={itemKey} className={classNames(tdClassName, itemClassName)}>
                        {renderItem ? renderItem(colValue, item) : colValue}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </>
        ) : (
          <tr>
            <td>{noDataElement}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default React.memo(BaseTable) as typeof BaseTable
