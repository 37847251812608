import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { ReactNode } from 'react'
import classNames from 'lib/classnames'
import isFunction from 'lodash/isFunction'

interface Props {
  defaultOpen?: boolean
  children: ReactNode
  title: string | ReactNode | ((open: boolean) => ReactNode)
  titleClassName?: string
  containerClassName?: string
  iconClassName?: string
  className?: string
}

export function DisclosurePanel({
  title,
  children,
  defaultOpen,
  className,
  containerClassName,
  iconClassName = 'h-5 w-5',
  titleClassName
}: Props) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div className={containerClassName || 'dark:bg-sentio-gray-200 w-full rounded bg-[#F6F8FA]'}>
          <Disclosure.Button
            className={classNames(
              open ? 'rounded-t' : 'rounded',
              'focus-visible:ring-primary-500 text-ilabel font-ilabel text-text-foreground flex w-full px-2 py-1.5 text-left hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-opacity-75',
              titleClassName
            )}
          >
            <ChevronRightIcon
              className={classNames(
                open ? 'rotate-90 transform' : '',
                'mr-1 self-center transition-all',
                iconClassName
              )}
            />
            {isFunction(title) ? title(open) : title}
          </Disclosure.Button>
          <Disclosure.Panel className={classNames('p-2', className)}>{children}</Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}
