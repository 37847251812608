/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../fetch.pb"
import * as GoogleProtobufEmpty from "../../../google/protobuf/empty.pb"
import * as GoogleProtobufStruct from "../../../google/protobuf/struct.pb"
import * as GoogleProtobufTimestamp from "../../../google/protobuf/timestamp.pb"
import * as CommonCommon from "../../common/protos/common.pb"

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (
    keyof T extends infer K ?
      (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K>
        : never)
    : never);

export enum ProcessorVersionState {
  UNKNOWN = "UNKNOWN",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  OBSOLETE = "OBSOLETE",
}

export enum SystemStatType {
  CPU = "CPU",
  MEM = "MEM",
}

export enum ChainStateStatusState {
  UNKNOWN = "UNKNOWN",
  ERROR = "ERROR",
  CATCHING_UP = "CATCHING_UP",
  PROCESSING_LATEST = "PROCESSING_LATEST",
  QUEUING = "QUEUING",
}

export enum GetProcessorStatusResponseProcessorStatusState {
  UNKNOWN = "UNKNOWN",
  ERROR = "ERROR",
  STARTING = "STARTING",
  PROCESSING = "PROCESSING",
}

export type InitUploadRequest = {
  projectSlug?: string
  sdkVersion?: string
  sequence?: number
}

export type InitUploadResponse = {
  url?: string
  warning?: string
}

export type NetworkOverride = {
  chain?: string
  host?: string
}

export type FinishUploadRequest = {
  projectSlug?: string
  sdkVersion?: string
  sha256?: string
  commitSha?: string
  gitUrl?: string
  debug?: boolean
  sequence?: number
  continueFrom?: number
  cliVersion?: string
  networkOverrides?: NetworkOverride[]
  warnings?: string[]
}

export type FinishUploadResponse = {
  projectFullSlug?: string
  version?: number
  processorId?: string
}

export type DownloadProcessorRequest = {
  processorId?: string
}

export type DownloadProcessorResponse = {
  url?: string
}

export type GetProcessorCodeRequest = {
  id?: string
  historyId?: string
}

export type GetProcessorCodeResponse = {
  codeUrl?: string
  zipUrl?: string
}

export type GetProjectVersionsRequest = {
  projectId?: string
}

export type SetProcessorEntitySchemaRequest = {
  processorId?: string
  schema?: string
}

export type GetProjectVersionsResponseVersion = {
  version?: number
  state?: ProcessorVersionState
  processorId?: string
}

export type GetProjectVersionsResponse = {
  versions?: GetProjectVersionsResponseVersion[]
}

export type CheckKeyResponse = {
  username?: string
}

export type GetProcessorsRequest = {
  projectId?: string
}

export type GetProcessorsResponse = {
  processors?: Processor[]
}

export type GetProjectVariablesRequest = {
  projectId?: string
}

export type GetProcessorRequest = {
  processorId?: string
}

export type RerunProcessorRequest = {
  processorId?: string
  sdkVersion?: string
}

export type GetProcessorResponse = {
  processor?: Processor
}

export type GetProcessorUpgradeHistoryRequest = {
  processorId?: string
}

export type GetProcessorUpgradeHistoryResponse = {
  histories?: ProcessorUpgradeHistory[]
}

export type GetProcessorWithProjectResponse = {
  processor?: Processor
  project?: CommonCommon.Project
}

export type ResumeWaitingProcessorsRequest = {
  ownerId?: string
  ownerType?: string
}

export type UpdateChainProcessorStatusRequest = {
  id?: string
  chainState?: ChainState
}

export type RecordNotificationRequest = {
  projectId?: string
  source?: string
  level?: string
  msg?: string
  attributes?: {[key: string]: string}
}

export type UpdateChainProcessorStatusResponse = {
}

export type ChainStateStatus = {
  state?: ChainStateStatusState
  errorRecord?: CommonCommon.ErrorRecord
}

export type ChainState = {
  chainId?: string
  processedBlockNumber?: string
  processedTimestampMicros?: string
  processedBlockHash?: string
  processedVersion?: number
  status?: ChainStateStatus
  updatedAt?: GoogleProtobufTimestamp.Timestamp
  templates?: string
  indexerState?: string
  meterState?: string
  initialStartBlockNumber?: string
  estimatedLatestBlockNumber?: string
  trackers?: string
}

export type Processor = {
  processorId?: string
  projectId?: string
  version?: number
  sdkVersion?: string
  codeUrl?: string
  chainStates?: ChainState[]
  contractId?: string
  versionState?: ProcessorVersionState
  debug?: boolean
  timescaleShardingIndex?: number
  versionLabel?: string
  ipfsHash?: string
  debugFork?: string
  createdAt?: string
  clickhouseShardingIndex?: number
  k8SClusterId?: number
  enableMaterializedView?: boolean
  referenceProjectId?: string
  networkOverrides?: NetworkOverride[]
}

export type ProcessorUpgradeHistory = {
  index?: number
  uploadedAt?: GoogleProtobufTimestamp.Timestamp
  obsoleteAt?: GoogleProtobufTimestamp.Timestamp
  snapshot?: Processor
  id?: string
}


type BaseGetProcessorStatusRequest = {
}

export type GetProcessorStatusRequest = BaseGetProcessorStatusRequest
  & OneOf<{ projectId: string; id: string }>

export type GetProcessorStatusResponseProcessorEx = {
  states?: ChainState[]
  processorId?: string
  codeHash?: string
  commitSha?: string
  uploadedBy?: CommonCommon.UserInfo
  uploadedAt?: GoogleProtobufTimestamp.Timestamp
  processorStatus?: GetProcessorStatusResponseProcessorStatus
  version?: number
  sdkVersion?: string
  gitUrl?: string
  versionState?: ProcessorVersionState
  versionLabel?: string
  ipfsHash?: string
  debugFork?: string
  cliVersion?: string
  referenceProjectId?: string
  warnings?: string[]
}

export type GetProcessorStatusResponseProcessorStatus = {
  state?: GetProcessorStatusResponseProcessorStatusState
  errorRecord?: CommonCommon.ErrorRecord
}

export type GetProcessorStatusResponse = {
  processors?: GetProcessorStatusResponseProcessorEx[]
}

export type ProcessorIdRequest = {
  id?: string
}

export type RemoveProcessorResponse = {
  deleted?: Processor
}

export type GetLogsRequest = {
  processorId?: string
  limit?: number
  after?: CommonCommon.Any[]
  logTypeFilters?: string[]
  query?: string
}

export type GetLogsResponseLog = {
  id?: string
  message?: string
  timestamp?: GoogleProtobufTimestamp.Timestamp
  attributes?: GoogleProtobufStruct.Struct
  logType?: string
  level?: string
  highlightedMessage?: string
  chainId?: string
}

export type GetLogsResponse = {
  logs?: GetLogsResponseLog[]
  after?: CommonCommon.Any[]
  total?: string
}

export type GetSystemStatRequest = {
  processorId?: string
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
  type?: SystemStatType
}

export type GetSystemStatResponseMetricData = {
  metric?: {[key: string]: string}
  values?: GetSystemStatResponseDataPoint[]
}

export type GetSystemStatResponseDataPoint = {
  timestamp?: string
  value?: string
}

export type GetSystemStatResponse = {
  results?: GetSystemStatResponseMetricData[]
}

export type GetChainBlockStatRequest = {
  processorId?: string
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
}

export type GetChainBlockStatResponse = {
  results?: GetSystemStatResponseMetricData[]
  headResults?: GetSystemStatResponseMetricData[]
}

export type GetProcessorProfileRequest = {
  processorId?: string
  time?: number
}

export type GetProcessorProfileResponse = {
  profileUrl?: string
  profileData?: GoogleProtobufStruct.Struct
}

export class ProcessorService {
  static GetProcessors(req: GetProcessorsRequest, initReq?: fm.InitReq): Promise<GetProcessorsResponse> {
    return fm.fetchReq<GetProcessorsRequest, GetProcessorsResponse>(`/processor_service.ProcessorService/GetProcessors`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetProcessor(req: GetProcessorRequest, initReq?: fm.InitReq): Promise<GetProcessorResponse> {
    return fm.fetchReq<GetProcessorRequest, GetProcessorResponse>(`/processor_service.ProcessorService/GetProcessor`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetProcessorWithProject(req: GetProcessorRequest, initReq?: fm.InitReq): Promise<GetProcessorWithProjectResponse> {
    return fm.fetchReq<GetProcessorRequest, GetProcessorWithProjectResponse>(`/processor_service.ProcessorService/GetProcessorWithProject`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetProjectVariables(req: GetProjectVariablesRequest, initReq?: fm.InitReq): Promise<CommonCommon.ProjectVariables> {
    return fm.fetchReq<GetProjectVariablesRequest, CommonCommon.ProjectVariables>(`/processor_service.ProcessorService/GetProjectVariables`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpdateChainProcessorStatus(req: UpdateChainProcessorStatusRequest, initReq?: fm.InitReq): Promise<UpdateChainProcessorStatusResponse> {
    return fm.fetchReq<UpdateChainProcessorStatusRequest, UpdateChainProcessorStatusResponse>(`/processor_service.ProcessorService/UpdateChainProcessorStatus`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RecordNotificationInternal(req: RecordNotificationRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<RecordNotificationRequest, GoogleProtobufEmpty.Empty>(`/processor_service.ProcessorService/RecordNotificationInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RemoveProcessorInternal(req: ProcessorIdRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<ProcessorIdRequest, GoogleProtobufEmpty.Empty>(`/processor_service.ProcessorService/RemoveProcessorInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static StartProcessorInternal(req: ProcessorIdRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<ProcessorIdRequest, GoogleProtobufEmpty.Empty>(`/processor_service.ProcessorService/StartProcessorInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DownloadProcessor(req: DownloadProcessorRequest, initReq?: fm.InitReq): Promise<DownloadProcessorResponse> {
    return fm.fetchReq<DownloadProcessorRequest, DownloadProcessorResponse>(`/processor_service.ProcessorService/DownloadProcessor`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static SetProcessorEntitySchema(req: SetProcessorEntitySchemaRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<SetProcessorEntitySchemaRequest, GoogleProtobufEmpty.Empty>(`/processor_service.ProcessorService/SetProcessorEntitySchema`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetProcessorCode(req: ProcessorIdRequest, initReq?: fm.InitReq): Promise<GetProcessorCodeResponse> {
    return fm.fetchReq<ProcessorIdRequest, GetProcessorCodeResponse>(`/api/v1/processors/code?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetProcessorHistoryCode(req: GetProcessorCodeRequest, initReq?: fm.InitReq): Promise<GetProcessorCodeResponse> {
    return fm.fetchReq<GetProcessorCodeRequest, GetProcessorCodeResponse>(`/api/v1/processors/code_history?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetProcessorStatusInternal(req: GetProcessorStatusRequest, initReq?: fm.InitReq): Promise<GetProcessorStatusResponse> {
    return fm.fetchReq<GetProcessorStatusRequest, GetProcessorStatusResponse>(`/processor_service.ProcessorService/GetProcessorStatusInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ResumeWaitingProcessorsInternal(req: ResumeWaitingProcessorsRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<ResumeWaitingProcessorsRequest, GoogleProtobufEmpty.Empty>(`/processor_service.ProcessorService/ResumeWaitingProcessorsInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetProcessorStatus(req: GetProcessorStatusRequest, initReq?: fm.InitReq): Promise<GetProcessorStatusResponse> {
    return fm.fetchReq<GetProcessorStatusRequest, GetProcessorStatusResponse>(`/api/v1/processors/status?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetProcessorUpgradeHistories(req: GetProcessorUpgradeHistoryRequest, initReq?: fm.InitReq): Promise<GetProcessorUpgradeHistoryResponse> {
    return fm.fetchReq<GetProcessorUpgradeHistoryRequest, GetProcessorUpgradeHistoryResponse>(`/api/v1/processors/${req["processorId"]}/history?${fm.renderURLSearchParams(req, ["processorId"])}`, {...initReq, method: "GET"})
  }
  static RemoveProcessor(req: ProcessorIdRequest, initReq?: fm.InitReq): Promise<RemoveProcessorResponse> {
    return fm.fetchReq<ProcessorIdRequest, RemoveProcessorResponse>(`/api/v1/processors/${req["id"]}`, {...initReq, method: "DELETE"})
  }
  static SetActive(req: GetProcessorRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<GetProcessorRequest, GoogleProtobufEmpty.Empty>(`/api/v1/processors/${req["processorId"]}/active`, {...initReq, method: "PUT"})
  }
  static RerunProcessorAsNewVersion(req: RerunProcessorRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<RerunProcessorRequest, GoogleProtobufEmpty.Empty>(`/api/v1/processors/${req["processorId"]}/rerun`, {...initReq, method: "PUT"})
  }
  static RestartProcessor(req: GetProcessorRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<GetProcessorRequest, GoogleProtobufEmpty.Empty>(`/api/v1/processors/${req["processorId"]}/restart`, {...initReq, method: "PUT"})
  }
  static GetProjectVersions(req: GetProjectVersionsRequest, initReq?: fm.InitReq): Promise<GetProjectVersionsResponse> {
    return fm.fetchReq<GetProjectVersionsRequest, GetProjectVersionsResponse>(`/api/v1/processors/${req["projectId"]}/versions?${fm.renderURLSearchParams(req, ["projectId"])}`, {...initReq, method: "GET"})
  }
  static CheckKey(req: GoogleProtobufEmpty.Empty, initReq?: fm.InitReq): Promise<CheckKeyResponse> {
    return fm.fetchReq<GoogleProtobufEmpty.Empty, CheckKeyResponse>(`/api/v1/processors/check_key?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static InitUpload(req: InitUploadRequest, initReq?: fm.InitReq): Promise<InitUploadResponse> {
    return fm.fetchReq<InitUploadRequest, InitUploadResponse>(`/api/v1/processors/init_upload`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static FinishUpload(req: FinishUploadRequest, initReq?: fm.InitReq): Promise<FinishUploadResponse> {
    return fm.fetchReq<FinishUploadRequest, FinishUploadResponse>(`/api/v1/processors/finish_upload`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetLogs(req: GetLogsRequest, initReq?: fm.InitReq): Promise<GetLogsResponse> {
    return fm.fetchReq<GetLogsRequest, GetLogsResponse>(`/api/v1/processors/${req["processorId"]}/logs`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetSystemStat(req: GetSystemStatRequest, initReq?: fm.InitReq): Promise<GetSystemStatResponse> {
    return fm.fetchReq<GetSystemStatRequest, GetSystemStatResponse>(`/api/v1/processors/${req["processorId"]}/stats`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetChainBlockStat(req: GetChainBlockStatRequest, initReq?: fm.InitReq): Promise<GetChainBlockStatResponse> {
    return fm.fetchReq<GetChainBlockStatRequest, GetChainBlockStatResponse>(`/api/v1/processors/${req["processorId"]}/chain_stats`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetProcessorProfile(req: GetProcessorProfileRequest, initReq?: fm.InitReq): Promise<GetProcessorProfileResponse> {
    return fm.fetchReq<GetProcessorProfileRequest, GetProcessorProfileResponse>(`/api/v1/processors/${req["processorId"]}/profile`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}