import { useMemo } from 'react'
import { ChainState, ChainStateStatus } from 'gen/service/processor/protos/service.pb'
import { ChainStateStatusState } from 'gen/service/processor/protos/service.pb'
import { getChainType } from 'lib/chain'
import { getChainName } from '@sentio/chain'

import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import StatusBadge from '../common/badge/StatusBadge'
import Table from 'components/common/table/BaseTable'
import { DisclosurePanel } from 'components/common/panel/DisclosurePanel'
import { upperFirst, groupBy } from 'lodash'

import dayjs from 'dayjs'
import { chainBlockLabel, chainInitialLabel } from '../../lib/chain'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

const ChainStateTable = Table<ChainState>

export const ChainStateText: { [key: string]: string } = {
  [ChainStateStatusState.UNKNOWN]: 'Unknown',
  [ChainStateStatusState.CATCHING_UP]: 'Backfill',
  [ChainStateStatusState.QUEUING]: 'Queuing',
  [ChainStateStatusState.ERROR]: 'Error',
  [ChainStateStatusState.PROCESSING_LATEST]: 'Watching'
}

export const ChainStateColors: { [key: string]: string } = {
  [ChainStateStatusState.UNKNOWN]: 'text-gray-600',
  [ChainStateStatusState.QUEUING]: 'text-orange-600',
  [ChainStateStatusState.CATCHING_UP]: 'text-orange-600',
  [ChainStateStatusState.ERROR]: 'text-red-600',
  [ChainStateStatusState.PROCESSING_LATEST]: 'text-cyan-600'
}

export function getChainProgress(data: ChainState) {
  const { processedBlockNumber, initialStartBlockNumber, estimatedLatestBlockNumber } = data
  if (!processedBlockNumber || !initialStartBlockNumber || !estimatedLatestBlockNumber) return 0
  const result = Math.max(
    0,
    (parseInt(processedBlockNumber) - parseInt(initialStartBlockNumber)) /
      (parseInt(estimatedLatestBlockNumber) - parseInt(initialStartBlockNumber) + 1)
  )
  return Math.min(1, result)
}

const ChainProgress = ({ data }: { data: ChainState }) => {
  if (
    data?.status?.state &&
    [ChainStateStatusState.CATCHING_UP, ChainStateStatusState.QUEUING, ChainStateStatusState.ERROR].includes(
      data.status.state
    )
  ) {
    let progress = getChainProgress(data)
    if (isNaN(progress)) {
      progress = 0
    } else if (progress > 1) {
      progress = 1
    }
    return <span className="text-icontent font-icontent text-gray">{(progress * 100).toFixed(2)}%</span>
  }
  return null
}

const ProcessorChain = ({ chainType, chainStates: _chainStates }: { chainType: string; chainStates: ChainState[] }) => {
  const chainStates = useMemo(() => {
    return _chainStates.sort((a, b) => getChainName(a.chainId || '').localeCompare(getChainName(b.chainId || '')))
  }, [_chainStates])
  const columns = useMemo(() => {
    return [
      {
        title: 'Status',
        itemKey: 'status',
        renderItem: (status: ChainStateStatus, state: ChainState) => {
          if (!status || !status.state) return null
          let error = status?.errorRecord?.message
          if (error && status?.errorRecord?.createdAt) {
            error = `[${status?.errorRecord?.createdAt}] ${error}`
          }
          return (
            <span className="space-x-2">
              <StatusBadge
                status={ChainStateText[status.state]}
                colorClasses={ChainStateColors[status.state]}
                className="px-0"
                error={error}
                bubble
              />
              <ChainProgress data={state} />
            </span>
          )
        }
      },
      {
        title: 'Chains',
        itemKey: 'chainId',
        renderItem: (chainId: string) => {
          return <span>{chainId && getChainName(chainId)}</span>
        }
      },

      ...[
        {
          title: chainInitialLabel(chainType),
          itemKey: 'initialStartBlockNumber',
          itemClassName: 'font-icontent text-gray'
        },
        {
          title: chainBlockLabel(chainType),
          itemKey: 'processedBlockNumber',
          itemClassName: 'font-icontent text-gray'
        }
      ],
      {
        title: 'Last updated',
        itemKey: 'updatedAt',
        itemClassName: 'font-icontent text-gray',
        renderItem: (updatedAt: string) => dayjs(updatedAt).fromNow()
      }
    ]
  }, [chainType])

  return (
    <DisclosurePanel
      title={upperFirst(chainType)}
      key={chainType}
      defaultOpen
      containerClassName="bg-white dark:bg-sentio-gray-100 border border-border-color rounded"
      className="pt-0"
    >
      <ChainStateTable rowKey="chainId" columns={columns} data={chainStates} />
    </DisclosurePanel>
  )
}

interface Props {
  chainStates: ChainState[]
}

export const ProcessorChains = ({ chainStates }: Props) => {
  const groupedStates = useMemo(() => {
    return groupBy(chainStates, (s) => getChainType(s.chainId))
  }, [chainStates])

  if (!chainStates || chainStates.length === 0) return null

  return (
    <div className="overflow-auto">
      <div className="inline-block min-w-full space-y-4 align-middle">
        {Object.keys(groupedStates)
          .sort()
          .map((chainType) => (
            <ProcessorChain key={chainType} chainType={chainType} chainStates={groupedStates[chainType]} />
          ))}
      </div>
    </div>
  )
}
