import React, { useContext } from 'react'
import classNames from 'lib/classnames'
import { CopyButton } from '../buttons/CopyButton'
import Avatar from 'boring-avatars'
import { getChainExternalUrl, getChainBlockscoutUrl } from '@sentio/chain'
import { useResizeDetector } from 'react-resize-detector'
import { chainIdToNumber, useAddressTag } from 'lib/data/use-tag'
import { cva } from 'class-variance-authority'
import { ExternalLogo } from './ExternalLogo'
import { getPathHostName } from 'lib/debug/helpers'
import { SvgFolderContext } from 'lib/extension/context'

const iconClassName = cva('', {
  variants: {
    size: {
      sm: '!w-4 !h-4',
      lg: '!w-5 !h-5'
    }
  },
  defaultVariants: {
    size: 'sm'
  }
})

interface Props {
  data: string
  className?: string
  truncate?: number
  autoTruncate?: boolean
  copyable?: boolean
  avatar?: boolean
  avatarProps?: React.ComponentProps<typeof Avatar>
  chainId?: string
  type?: 'address' | 'tx' | 'block'
  trigger?: 'hover' | 'static'
  noCopyHint?: boolean // for performance reason, we could disable the copy hint
  showTag?: boolean
  link?: string
  size?: 'sm' | 'lg'
  noLink?: boolean
}

export const HexNumber = React.memo(function HexNumber({
  data,
  className,
  truncate: _truncate,
  autoTruncate,
  copyable,
  avatar,
  avatarProps,
  chainId,
  type,
  trigger = 'hover',
  noCopyHint = true,
  showTag,
  link,
  size = 'sm',
  noLink = false
}: Props) {
  let displayString = data
  let title: string | undefined = undefined
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'throttle',
    refreshRate: 1000
  })
  const { data: tagData } = useAddressTag(showTag ? data : undefined, chainId)
  const folderPath = useContext(SvgFolderContext)

  if (data === undefined || data === null) {
    return null
  }

  const externalLink = link || getChainExternalUrl(chainIdToNumber(chainId), data, type)
  const blockscoutLink = getChainBlockscoutUrl(chainIdToNumber(chainId), data, type)

  let truncate = _truncate
  if (autoTruncate && width && width > 0) {
    let totalWidth = width - 8
    if (externalLink) {
      totalWidth = totalWidth - 24
    }
    if (blockscoutLink) {
      totalWidth = totalWidth - 24
    }
    if (copyable) {
      totalWidth -= 24
    }
    if (avatar) {
      totalWidth -= 16
    }
    truncate = Math.max(8, Math.floor(totalWidth / 8))
  }
  if (truncate && data.length > truncate) {
    displayString = `${data.substring(0, truncate - 4)}...${data.substring(data.length - 4)}`
    title = data
  }
  if (tagData?.primaryName) {
    displayString = tagData.primaryName
  }

  return (
    <span
      className={classNames(
        'number items-center gap-2',
        className,
        copyable && 'group flex-nowrap',
        autoTruncate ? 'flex w-full' : 'inline-flex'
      )}
      ref={ref}
    >
      {avatar ? (
        <Avatar
          size={16}
          name={data.startsWith('0x') ? data.substring(2) : data}
          variant="pixel"
          colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
          {...avatarProps}
        />
      ) : null}
      <span title={title}>{displayString}</span>
      {copyable ? (
        <div onClick={(e) => e.stopPropagation()}>
          <CopyButton
            text={data}
            iconClass={classNames(
              'hover:text-primary',
              trigger === 'static' ? '' : 'collapse group-hover:visible',
              iconClassName({ size })
            )}
            noHint={noCopyHint}
          />
        </div>
      ) : null}
      {!noLink && externalLink ? (
        <a
          href={externalLink}
          target="_blank"
          rel="noreferrer"
          title={getPathHostName(externalLink)}
          className="w-max shrink-0"
        >
          <ExternalLogo
            className={classNames(
              'hover:text-primary active:text-primary-700',
              trigger === 'static' ? '' : 'collapse group-hover:visible',
              iconClassName({ size })
            )}
            link={externalLink}
          />
        </a>
      ) : null}
      {!noLink && blockscoutLink ? (
        // eslint-disable-next-line react/jsx-no-target-blank -- blockscout need track sentio referrer
        <a
          href={blockscoutLink}
          target="_blank"
          title="Blockscout"
          className="w-max shrink-0 rounded-full hover:ring-2"
        >
          <img
            src={`${folderPath}/blockscout-logo.png`}
            alt="Blockscout Logo"
            className={classNames(
              'hover:text-primary active:text-primary-700 rounded-full',
              trigger === 'static' ? '' : 'collapse group-hover:visible',
              iconClassName({ size })
            )}
          />
        </a>
      ) : null}
    </span>
  )
})
